import { useState } from "react";
import "./SelfReflection.scss";

const SRQuestion = (props) => {
  const { data, onChange } = props;
  const [current, setCurrent] = useState("");

  const handleChange = (e) => {
    onChange(e.currentTarget.value);
    setCurrent(e.currentTarget.value);
  };
  return (
    <div className="SRQuestion">
      <div
        className="question-text"
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></div>

      <textarea
        type="text"
        value={current}
        onChange={handleChange}
        style={props.questionHeight ? { minHeight: props.questionHeight } : {}}
        placeholder=""
      />
    </div>
  );
};

const SelfReflection = (props) => {
  const { data, onComplete } = props;
  const { leftPanel, questions } = data;


  const handleNext = () => {
    // onComplete();
    if (inSetup) {
      setInSetup(false);
    } else {
      setInOutro(true)
    }
  };

  const [answers, setAnswers] = useState(questions.map(() => null));
  const [inOutro, setInOutro] = useState(false);
  const [inSetup, setInSetup] = useState(data.setup !== undefined);

  return (
    <div className={`SelfReflection `}>
      <div className="left-blue-panel">
        {leftPanel.heading && <h1>{leftPanel.heading}</h1>}

        {leftPanel.body && (
          <div
            className="body"
            dangerouslySetInnerHTML={{ __html: leftPanel.body }}
          ></div>
        )}
      </div>
      <div className="questions">
        {
          inOutro &&
          <div className={`outro`}>
            <p>Thanks for taking the time to reply.</p>
            <button
              className="std-button"
              onClick={onComplete}
            >
              {"Next"}
            </button>
          </div>
        }
        {
          !inOutro && data.setup &&
          <div className={`setup`}>
            <div className={`square `} >
              <img src={data.setup.card.image.file} alt={data.setup.card.image.alt} />
              <div className={`quotes`}>
                {
                  data.setup.card.quotes.map((quote, quoteIndex) => {
                    return <div className={`quote`} key={'q' + quoteIndex} style={{
                      ...quote.position
                    }
                    }>
                      {
                        quote.text
                      }
                    </div>
                  })
                }
              </div>
            </div>
          </div>
        }
        {!inOutro && !inSetup && questions.map((question, questionIndex) => {
          return (
            <SRQuestion
              data={question}
              questionHeight={data.questionHeight}
              onChange={(val) => {
                let temp = [...answers];
                temp[questionIndex] = val;
                setAnswers(temp);
              }}
              key={`question${questionIndex}`}
            />
          );
        })}
      
      </div>
      {!inOutro &&
            <button
              className="std-button"
              onClick={handleNext}
              disabled={
                inSetup ? false :
                  answers.filter((el) => el === null).length > 0}
            >
              {"Next"}
            </button>
        }
    </div>
  );
};

export default SelfReflection;
