import React, { useState } from "react";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="Header">
      <div className="blue-bar"></div>
      <div className="logo-wrapper">
        <img
          src="assets/soteria-logo.png"
          alt="Soteria Logo"
          style={{
            margin: "3px 30px",
          }}
        />
        <img
          src="assets/noaa-logo.png"
          alt="NOAA Logo"
          style={{
            margin: "0px 0px 15px",
          }}
        />
      </div>
      <div className="text">
        <h1>{props.text}</h1>
      </div>
      <div className={`hamburger-menu`}>
        <button className={`btn`} onClick={() => setMenuOpen(!menuOpen)}>
          {!menuOpen ? (
            <div className={`btn-text`}>MENU</div>
          ) : (
            <div className={`btn-text`}>X</div>
          )}
        </button>
        {menuOpen && (
          <div className={`menu`}>
            <ul>
              <li>
                <button tabIndex={0} onClick={() => props.backOneSlide()}>
                  Go back one slide
                </button>
              </li>
              <li>
                <button tabIndex={0} onClick={() => props.goToSlide(0)}>
                  Restart lesson
                </button>
              </li>
              <li>
                <button
                  tabIndex={0}
                  onClick={() =>
                    props.setLocation({
                      module: 0,
                      slide: 0,
                    })
                  }
                >
                  Restart course
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
