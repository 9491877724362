import React, {  } from "react";

const Part2 = (props) => {
  const { data, recentThought, thoughtsReviewed, onComplete } = props;

  return (
    <div className="Part2">
      <div className={`heading-text`}>
        <h1> {data.heading}</h1>
      </div>
      <div className={`wrap`}>
        <div className={`text`} dangerouslySetInnerHTML={{__html:data.instructions}}></div>
        <div className="stats">
          {recentThought && <div className={`stat`}>{recentThought}</div>}
        </div>
      </div>
      {thoughtsReviewed.filter((el) => !el).length === 0 && (
        <button className="next-btn std-button" onClick={onComplete}>
          {"Next"}
        </button>
      )}
    </div>
  );
};

export default Part2;
