import { useEffect, useState } from "react";
import "./Pager.scss";

const Pager = (props) => {
  const { data, onComplete } = props;
  const { heading, body, image, layout, buttonText, tinyText } = data;

  const [cleanup, setCleanup] = useState(false);
  const handleNext = () => {
    // onComplete();
    if (!cleanup) setCleanup(true);
  };

  useEffect(() => {
    if (cleanup) {
      setTimeout(onComplete, 900);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanup]);

  return (
    <div className={`Pager ${cleanup ? "cleanup" : ""} ${layout}`}>
      {(layout === 'right-blue-panel') && image && (
        <div className="image-wrapper">
          <img src={image.file} alt={image.alt} />
        </div>
      )}

      <div className="blue-panel">
        {heading && <h1>{heading}</h1>}

        {body && (
          <div
            className="pager-body"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        )}
       
        <button className="std-button" onClick={handleNext}>
          {buttonText || "Next"}
        </button>
      </div>
      {(layout === 'left-blue-panel') && image && (
        <div className="image-wrapper">
          <img src={image.file} alt={image.alt} />
        </div>
      )}
    </div>
  );
};

export default Pager;
