import React, { useState } from "react";
import "./SingleSelect.scss";

const SingleSelect = (props) => {
    const { data, onComplete } = props;
    const {question, options, image} = data;
    const [answer, setAnswer] = useState(-1);

    const handleNext = () => {
        onComplete();
    };

    return (
        <div className="SingleSelect">
            {image && (
        <div className="image-wrapper">
          <img src={image.file} alt={image.alt} />
        </div>
      )}
            <div className={`question`}>
                {question.heading && (
                    <h1 className="question-heading"> {question.heading}</h1>
                )}
                {question.body && (
                    <div
                        className={`question-body`}
                        dangerouslySetInnerHTML={{ __html: question.body }}
                    ></div>
                )}
            </div>
            <div className={`options`}>
                {options.map((option, optionIndex) => {
                    return (
                        <button className={`option-btn ${answer === optionIndex ? 'selected' : 'not-selected'}`} key={`option${optionIndex}`} onClick={()=>setAnswer(optionIndex)}>
                            {option.text}
                        </button>
                    );
                })}
            </div>
            
            <button
            className="std-button" 
                onClick={handleNext}
                disabled={answer === -1}
            >
                {"Next"}
            </button>
        </div>
    );
};

export default SingleSelect;
