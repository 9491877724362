import Module1 from './module-1.json';
import Module2 from './module-2.json';
import Module3 from './module-3.json';
import Module4 from './module-4.json';

const data = {
        modules:[
            Module1,
            Module2,
            Module3,
            Module4,
        ],
        showJira:true,
        showQuickNav:true,
        jiraTag:"https://hunterlearning.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/bc54840da492f9ca037209037ef0522a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=e2e7fb1c",
        
    }

export default data