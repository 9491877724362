import React, { useState } from "react";
import Bubble from "./Bubble";
import Part1 from "./Part1";
import Part2 from "./Part2";
import Part3 from "./Part3";
import "./ETSModel.scss";

const ETSModel = (props) => {
  const { data, onComplete } = props;
  const { scene, part1, part2, part3 } = data;

  const [part, setPart] = useState(1);
  const [recentThought, setRecentThought] = useState(null);
  const [greenScrim, setGreenScrim] = useState(false);
  const [thoughtsReviewed, setThoughtsReviewed] = useState(
    scene.bubbles.map(bubble => bubble.flippable === false)
  );

  const handlePartComplete = () => {
    if (part < 3) {
      setPart(part + 1);
      if(part === 2){
          setGreenScrim(true)
      }
    } else {
      onComplete();
    }
  };
  if (!scene) return <></>;
  return (
    <div className="ETSModel">
      <div className={`img-wrapper`}>
        <img src={scene.image.file} alt={scene.image.alt} />
        <div className={`scrim p${part}`}></div>
      </div>

      {
        <div className={`bubbles`}>
          {scene.bubbles.map((bubble, bubbleIndex) => {
            return (
              <Bubble
                initial={part===1}
                data={bubble}
                onExpand={() => {
                  let temp = [...thoughtsReviewed];
                  temp[bubbleIndex] = true;
                  setThoughtsReviewed(temp);
                  setRecentThought(bubble.stats);
                }}
                active={part === 2 || part === 3}
                key={`bubble${bubbleIndex}`}
              />
            );
          })}
        </div>
      }
      {greenScrim && <div className={`green-scrim p${part}`}></div>}
      {part === 1 && <Part1 data={part1} onComplete={handlePartComplete} />}
      {part === 2 && (
        <Part2
          data={part2}
          thoughtsReviewed={thoughtsReviewed}
          recentThought={recentThought}
          onComplete={handlePartComplete}
        />
      )}
      {part === 3 && <Part3 data={part3} onComplete={handlePartComplete} />}
    </div>
  );
};

export default ETSModel;
