import React, { useEffect, useState } from "react";
import Intro from './Intro'
import "./InterventionModel.scss";
import Bubble from "../ETSModel/Bubble";

const InterventionModel = (props) => {
  const { data, onComplete } = props;
  const { scenes, finalFeedback } = data;
  const [current, setCurrent] = useState(0);
  const [inIntervention, setInIntervention] = useState(false);
  const [inFinalFeedback, setInFinalFeedback] = useState(false);
  const [answer, setAnswer] = useState(-1);
  //only start in intro if we have intro data
  const [inIntro, setInIntro] = useState(!data.intro.skip);
  const [modalOpen, setModalOpen] = useState(!!data.modal);

  const reset = () => {
    setAnswer(-1);
    setCurrent(0);
    setInIntervention(false);
    setInFinalFeedback(false);
  };
  const handlePrev = () => {
    setCurrent(current - 1);
  };
  const handleNext = () => {
    if (inFinalFeedback) {
      if (current + 1 >= scenes.length) {
        onComplete();
      } else {
        setCurrent(current + 1);
      }
    } else {
      setCurrent(current + 1);
    }
  };
  const handleIntervene = () => {
    setInIntervention(true);
  };

  const handleIntroComplete = () => {
    setInIntro(false)
  }

  useEffect(() => {
    if (inIntervention) return;
    if (answer > -1) {
      setInFinalFeedback(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inIntervention]);

  const getSceneClass = (i) => {
    if (i === current) return "current";
    if (i - 1 === current) return "next";
    if (i + 1 === current) return "prev";
    if (i > current) return "far-next";
    if (i < current) return "far-prev";
    return "other";
  };

  return (
    <div className="InterventionModel">
      <Intro data={data.intro} active={inIntro} onComplete={handleIntroComplete} phase={current}/>
      {modalOpen && !inIntro && props.data.modal && <div className={`modal`}>
        <div className={`main`}>  <h1 className={`heading`}>{props.data.modal.heading}</h1>
        <div className={`text`}>{props.data.modal.body}</div></div>
      
        <button className={'std-button'} onClick={()=>setModalOpen(false)}>Begin</button>
        </div>}
      {!inIntro && <div className={`film-strip`}>
        {/* <h1
          dangerouslySetInnerHTML={{
            __html: `${inFinalFeedback ? "Final Feedback " : ""
              }<span class="color">${phaseTitles[current]}</span>`,
          }}
        ></h1> */}
        {scenes.map((scene, sceneIndex) => {
          return (
            <div
              className={`scene  ${getSceneClass(sceneIndex)}`}
              key={`img${sceneIndex}`}
            >
              <img className={`item`} src={scene.file} alt={scene.alt} />

              {sceneIndex === current &&
                scene.bubbles &&
                !modalOpen &&
                (inFinalFeedback ? scene.options[answer].resultBubbles :
                  scene.bubbles).map((bubble, bubbleIndex) => {
                    return (
                      <Bubble
                        labelThoughts={true}
                        labelSays={true}

                        class={"flat"}
                        disableTail={true}
                        initial={true}
                        data={bubble}
                        onExpand={() => { }}
                        active={false}
                        key={`bubble${bubbleIndex} ${inFinalFeedback} `}
                      />
                    );
                  })}
            </div>
          );
        })}
      </div>
      }{!inFinalFeedback && !inIntro &&(
        <div
          className={`nav-buttons ${inIntervention ? "inactive" : "active"}`}
        >
          <div className={`line`}></div>
          <button
            disabled={current === 0 || inIntervention}
            className="std-button round reverse"
            onClick={handlePrev}
          >
            {/* Previous Zone */}
            {<img src="assets/arrow.png" alt="left arrow" />}
          </button>
          <button
            className="std-button big"
            onClick={handleIntervene}
            disabled={inIntervention}
          >
            Intervene
          </button>
          <button
            disabled={current + 1 >= scenes.length || inIntervention}
            className="std-button round"
            onClick={handleNext}
          >
            {/* Next Zone */}
            {<img src="assets/arrow.png" alt="right arrow" />}
          </button>
        </div>
      )}
      {
        inIntervention &&
        <div className={`q-scrim`}></div>
      }
      {!inFinalFeedback && !inIntro &&(
        <div className={`q-buttons ${inIntervention ? "active" : "inactive"}`}>
          <div className={`heading`}><h1>How would you like to intervene?</h1></div>
          <div className={`options`}>
            {inIntervention &&
              scenes[current] &&
              scenes[current].options &&
              scenes[current].options.map((option, optionIndex) => {
                if (answer > -1 && answer !== optionIndex) return null;
                return (
                  <button
                    className={`option-btn ${answer === -1
                        ? "default"
                        : answer === optionIndex
                          ? "selected"
                          : "not-selected"
                      }`}
                    key={`option${optionIndex}`}
                    onClick={() => setAnswer(optionIndex)}
                  >
                    {option.text}
                  </button>
                );
              })}
            {inIntervention &&
              (answer === -1 || answer === scenes[current].options.length) &&
              scenes[current] &&
              scenes[current].options && (
                <button
                  className="option-btn"
                  onClick={() => setInIntervention(false)}
                >
                  Never mind. Take me back to the scene.
                </button>
              )}
          </div>

          {answer > -1 && (
            <div className={`feedback`}>
              <div className="group">
                <h2>The Pros:</h2>
                <ul>
                  {scenes[current].options[answer].pros.map((pro, proIndex) => {
                    return <li key={`pro ${proIndex}`}>{pro}</li>;
                  })}
                </ul>
              </div>
              <div className="group">
                <h2>To Consider:</h2>
                <ul>
                  {scenes[current].options[answer].cons.map((con, conIndex) => {
                    return <li key={`con ${conIndex}`}>{con}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}

          {answer > -1 && (
            <div className={`action-btns`}>
              <button
                className={`std-button secondary`}
                onClick={() => setAnswer(-1)}
              >
                Go Back
              </button>
              <button
                className={`std-button`}
                onClick={() => setInIntervention(false)}
              >
                Commit This Choice
              </button>
            </div>
          )}
        </div>
      )}

      {inFinalFeedback && (
        <div className={`final-feedback`}>
          
          <div
            className={`body`}
            dangerouslySetInnerHTML={{ __html: finalFeedback.body }}
          ></div>
          <div className="btns">
            <button className={`std-button`} onClick={() => reset()}>
              Impact Another Moment
            </button>
            <button
              disabled={false}
              className={`std-button`}
              onClick={onComplete}
            >
              Next Situation
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InterventionModel;
