import React, {} from 'react'

const QuickNav = props => {
    const {CourseData, location, setLocation} = props;
    return (
        <nav className="QuickNav">
            <p>Reviewer <br/> Quick-Nav</p>
        {
            CourseData.modules.map( (el, i) => {
                return <div className={`mod`} key={`mod ${i}`}>
                     <p >Module {i+1}: {el.name}</p> 

                    
                    <div className="btns">
                        {el.slides.map((slide, si) => {
                            return <button className={(location.slide === si && location.module === i)  ? 'current' : 'not-current'}
                            onClick={()=>setLocation({slide:si, module:i})}
                            key={`nav ${si} ${i}`}>{si + 1}{slide.type !== "pager" ? '*' : ''}</button>
                        })}
                    </div>
                </div>
            })
        }
    </nav>
    )
}

export default QuickNav