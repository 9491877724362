import { useState } from "react";
import "./AudienceSelection.scss";

const AudienceToggle = (props) => {
  const { data, onChange, value } = props;

  const handleChange = (e) => {
    onChange(!value);
  };
  return (
    <div className="AudienceToggle">
      <button onClick={handleChange}
        className={`option-btn std-button ${value ? 'selected' : 'not-selected'}`}
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></button>

    
    </div>
  );
};

const AudienceSelection = (props) => {
  const { data, onComplete, setAudience } = props;
  const { leftPanel, options, disclaimer } = data;
  const [answers, setAnswers] = useState(options.map(() => false));

  const handleNext = () => {
      setAudience(options.filter((e,i) => answers[i]).map(el => el.id))
    onComplete();
  };


  return (
    <div className={`AudienceSelection `}>
      <div className="left-blue-panel">
        {leftPanel.heading && <h1>{leftPanel.heading}</h1>}

        {leftPanel.body && (
          <div
            className="body"
            dangerouslySetInnerHTML={{ __html: leftPanel.body }}
          ></div>
        )}
      </div>
      <div className={`disclaimer`}>{disclaimer}</div>
      <div className="options">
        <div className="btns">
            {options.map((option, optionIndex) => {
              return (
                <AudienceToggle
                  data={option}
                  value={answers[optionIndex]}
                  onChange={(val) => {
                    // let temp = [...answers]; //multiselect
                    let temp =answers.map(el => false) //singleselect
                    temp[optionIndex] = val;
                    setAnswers(temp);
                  }}
                  key={`option ${optionIndex}`}
                />
              );
            })}
        </div>
              <button
              className="next-btn std-button"
        onClick={handleNext}
        disabled={answers.filter((el) => el !== false).length === 0}
      >
        {"Next"}
      </button>
      </div>

    </div>
  );
};

export default AudienceSelection;
