/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {SCORM} from 'pipwerks-scorm-api-wrapper';

const Tracking = props => {

    const {location, setLocation, progress, setProgress, outroModal} = props;

    const [onInitData, setOnInitData] = useState(null);
    const onInit = (data) => {
      setOnInitData(data)
    }

    useEffect(()=>{
        if(onInitData === null) return;
        //restore progress
        if(onInitData.suspendData) setProgress(onInitData.suspendData)
        //drop us where we left off
        if(onInitData.lessonLocation) setLocation(onInitData.lessonLocation)
    }, [onInitData])

    //course is complete anytime our outro modal is open
    const courseComplete = outroModal;

    const trackingProps = {
        onInit,
        location,
        progress,
        courseComplete,
        version:"scorm2004"
    }
    return (<SCORMComponent {...trackingProps}/>)
}

const translate = {
    "scorm12":{
        "suspend_data":"cmi.suspend_data",
        "lesson_status":"cmi.core.lesson_status",
        "lesson_location":"cmi.core.lesson_location",
        "complete":"completed",
        "incomplete":"incomplete"
    },
    "scorm2004":{
        "suspend_data":"cmi.suspend_data",
        "lesson_status":"cmi.completion_status",
        "lesson_location":"cmi.location",
        "success_status":"cmi.success_status",
        "complete":"completed",
        "incomplete":"incomplete",
        "int_id":"cmi.interactions.n.id",
        "int_type":"cmi.interactions.n.type",
        "int_weight":"cmi.interactions.n.weighting",
        "int_choice":"cmi.interactions.n.learner_response",
        "int_result":"cmi.interactions.n.result",
        "int_description":"cmi.interactions.n.description",
        "score_scaled":"cmi.score.scaled",
        "score":"cmi.score.raw",
        "score_max":"cmi.score.max",
        "score_min":"cmi.score.min",
        "interaction_count":"cmi.interactions._count"
    }
};

const SCORMComponent = props => {

    const [connected, setConnected] = useState(false);

    const commit = () => {
        SCORM.save();
    }

    const get = (key) => {
        key = translate[props.version][key];
        return SCORM.get(key);    
    }

    const set = (key, val, autoCommit = false) => {
        const translated = translate[props.version][key];
        if(translated !== undefined){
            key = translated;
        }
        SCORM.set(key, val);
        if(autoCommit){
            commit();
        }
        return SCORM.debug.getCode();
    }
    
  

    const getSuspendData = () =>{
        const suspend = get('suspend_data');
        if(suspend == null || suspend === '' || suspend === undefined || suspend === 'null'){
            return false;
        }
        return JSON.parse(suspend);
    }

    useEffect(() => {
        console.log('SCORM initializing');
        const success = SCORM.init();
        setConnected(success);
        if(!success){
            console.log('SCORM connection failure')
            return;
        }
        let location = get('lesson_location');
        if(!(location == null || location === '' || location === undefined || location === 'null')){
            location = JSON.parse(location);
        }
        const bookmark = {
            lessonLocation:location,
            suspendData:getSuspendData()
        }

        props.onInit(bookmark)
        
        window.onbeforeunload = function(){
            SCORM.quit();
            // SCORM.save();
        }
    }, [])

    useEffect(() => {
        console.log('SCORM location/progress change')
        if(!connected) return;
        const bookmark = props.location;
        set('lesson_location', JSON.stringify(bookmark))
        set('suspend_data', JSON.stringify(props.progress))
    }, [props.location, props.progress])

    useEffect(() => {
        if(props.courseComplete) console.log('SCORM completion detected')
        if(!connected) return;

        if(props.courseComplete){
            set('lesson_status', translate[props.version]['complete']);
            if(props.scoScored){
                if(props.version === 'scorm2004'){
                    set('success_status', 'passed');
                }else{
                    set('success_status', 'failed');                
                }
            }else{
                set('success_status', 'passed');
            }
        }
        commit();
    }, [props.courseComplete])


    
    return (
        <></>
    )
}


export default Tracking

