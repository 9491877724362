/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import './Intro.scss';

const Intro = props => {
    const { data } = props;

    const [inClickReveal, setInClickReveal] = useState(true);
    const [current, setCurrent] = useState(-1);

    const [read, setRead] = useState(data ? data.reveals.map(el => false) : []);

    useEffect(()=>{
        if(current > -1){
            let temp = [...read];
            temp[current] = true;
            setRead(temp)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current])



    return <div className="Intro">

        {/* base */}
        {
            inClickReveal &&
            data &&
            props.active && 
            <div className={`cr-base ${current === -1 ? 'active' : 'inactive'}`}>
                <h1 className="heading" dangerouslySetInnerHTML={{ __html: data.baseState.heading }}></h1>
                <div className="instructions" dangerouslySetInnerHTML={{ __html: data.baseState.instructions }}></div>
                <div className="copy" dangerouslySetInnerHTML={{ __html: data.baseState.copy }}></div>
            </div>
        }

        {/* wheels */}
        <div className={`circles ${props.active ? 'active' : 'inactive'} `}>
            <div className={`bg ${current === -1 && props.active  ? 'shown' : 'hidden'}`}></div>
            {
                data &&
                data.reveals.map((circle, i) => {
                    return <div className={`circle ${!props.active && `phase-${props.phase}`} ${circle.class} ${current === -1 ? 'clickable' : current === i ? 'current' : 'not-current'}`} key={circle.title}>

                        <div className={`animation-wrapper
                        `}>
  <div className={`outer`}></div>
                        <button className={`inner`} onClick={() => setCurrent(i)}>
                            <div className={`text`}>{circle.title}</div>
                          
                        </button>

                        <div className={`reveal`}>
                                {(i === current) && <h1 className={`title`}>{circle.heading}</h1>}
                                {(i === current) && <div className={`body`}>{circle.body}</div>}
                                {(i === current) && <button onClick={() => setCurrent(-1)} className={`close-btn`}>x</button>}
                            </div>
                        </div>

                      
                    </div>
                })
            }
        </div>

        {current=== -1 && 
        props.active &&
        (read.filter(el => !el).length === 0) && 
        <button className={'std-button'} onClick={props.onComplete}>Next</button>}

    </div>

}

export default Intro;