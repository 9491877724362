import { useEffect, useState } from "react";
import CourseData from "../course-data/roadmap";
import InteractionLoader from "./InteractionLoader";
import Header from "./Header.js";
import QuickNav from "./QuickNav";
import Jira from "./Jira";
import Tracking from "./Tracking";
import _ from "lodash";

const Course = () => {
  const [location, setLocation] = useState({
    module: 0,
    slide: 0,
  });
  const [audience, setAudience] = useState([]);
  const [bigHeader, setBigHeader] = useState(false);
  const [outroModal, setOutroModal] = useState(false);
  const [progress, setProgress] = useState({
    modules: CourseData.modules.map((module) => {
      return {
        slides: module.slides.map((slide) => {
          return false;
        }),
      };
    }),
  });

  const markSlideComplete = (location) => {
    let temp = _.cloneDeep(progress);
    try {
      temp.modules[location.module].slides[location.slide] = true;
      setProgress(temp);
    } catch (error) {
      console.error(error);
    }
  };

  const backOneSlide = () => {
    const currentLocation = _.cloneDeep(location);
    if (currentLocation.slide > 0) {
      setLocation({
        ...currentLocation,
        slide: currentLocation.slide - 1,
      });
    } else if (currentLocation.module > 0) {
      setLocation({
        ...currentLocation,
        module: currentLocation.module - 1,
        slide: CourseData.modules[currentLocation.module - 1].length - 1,
      });
    } else {
      setLocation({
        slide: 0,
        module: 0,
      });
    }
  };

  const goToSlide = (index) => {
    const currentLocation = _.cloneDeep(location);
    let fixed = index;
    if (index < 0) {
      fixed = currentLocation.slide + index;
    }
    setLocation({
      ...currentLocation,
      slide: fixed,
    });
  };

  const goToModule = (index) => {
    const currentLocation = _.cloneDeep(location);
    setLocation({
      ...currentLocation,
      module: index,
    });
  };

  const handleSlideComplete = () => {
    //prevent any weird desync
    const currentLocation = _.cloneDeep(location);

    //mark slide complete
    markSlideComplete(currentLocation);

    //try next slide
    if (
      CourseData.modules[currentLocation.module].slides[
        currentLocation.slide + 1
      ] !== undefined
    ) {
      setLocation({
        ...currentLocation,
        slide: currentLocation.slide + 1,
      });
    } else if (CourseData.modules[currentLocation.module + 1] !== undefined) {
      //we are at end of module
      // alert("end of module");
      setLocation({
        ...currentLocation,
        module: currentLocation.module + 1,
        slide: 0,
      });
    } else {
      // alert('end of course')
      setOutroModal(true);
    }
  };

  const moduleData = CourseData.modules[location.module];
  const slideData = moduleData.slides[location.slide];

  useEffect(() => {
    if (!slideData) return;
    setBigHeader(!!slideData.bigHeader);
  }, [slideData]);

  useEffect(() => {
    if (!slideData) return;
    if((location.module < CourseData.modules.length - 1) || (location.slide < CourseData.modules[location.module].slides.length - 1)){
      setOutroModal(false);
    }
  }, [location]);

  const slideProps = {
    data: slideData,
    onComplete: handleSlideComplete,
    audience,
    setAudience,
    key: JSON.stringify(location),
  };

  return (
    <div className={`Course ${bigHeader ? "big-header" : ""}`}>
      <Header
        text={moduleData.name}
        goToModule={goToModule}
        goToSlide={goToSlide}
        setLocation={setLocation}
        backOneSlide={backOneSlide}
      />
      <div className="content">
        {InteractionLoader(slideData.type, slideProps)}
        {outroModal && (
          <div className={`OutroModal`}>
            <div className={`text`}>
              <h1>Congratulations!</h1>

              <p>
                Thank you for completing this module. Your participation is important to creating a safe, respectful and productive workplace for everyone. We invite you to complete a brief module evaluation form at: <br></br><a href={"https://soteria.iad1.qualtrics.com/jfe/form/SV_domZa0x1sRYlJmm"}>https://soteria.iad1.qualtrics.com/jfe/form/SV_domZa0x1sRYlJmm</a>
              </p>
              <p>
                Should you want to talk with someone about SASH and issues in this module, please contact the NOAA Workplace Violence Prevention and Response Program at: <a href="mailto:NOAA.victimservices@noaa.gov">NOAA.victimservices@noaa.gov</a><br></br> or <br></br><a href="https://www.noaa.gov/workplace-violence-prevention-response-program">https://www.noaa.gov/workplace-violence-prevention-response-program</a>
              </p>
              <p>
                Your progress has been recorded. You may close this window now.
              </p>
            </div>
          </div>
        )}
      </div>
      {CourseData.showQuickNav && (
        <QuickNav
          CourseData={CourseData}
          location={location}
          setLocation={setLocation}
        />
      )}
      {CourseData.showJira && (
        <Jira
          location={location}
          jiraTag={CourseData.jiraTag}
          interactionType={slideData.type}
        />
      )}
      <Tracking
        location={location}
        setLocation={setLocation}
        progress={progress}
        setProgress={setProgress}
        outroModal={outroModal}
      />
    </div>
  );
};

export default Course;
