import React, {useState} from 'react'
import bubTail from './thoughtBubs.png';
import talkTail from './talk-tail.png';
import './Bubble.scss'

const Bubble = props => {

    const loc = {
        top:props.data.location.top  || 0,
        left:props.data.location.left || 0,
        position:'absolute',
        animationDelay:props.data.location.delay + 's'
    }

    const [isThought, setIsThought] = useState(props.data.isThought || false);
    const [flipped, setFlipped] = useState(false);

    const handleExpand = () => {
        if(!props.active || (props.data.flippable === false)) return;
        setIsThought(!isThought)
        setFlipped(!flipped)
        props.onExpand()
    }
    return (
        <div className={`Bubble ${props.class}  ${props.active ? 'active' : "" } ${props.initial ? 'initial' : "" } ${isThought ? 'thought' : 'speech'} ${flipped ? 'flipped': 'not-flipped'}`} style={loc} onClick={handleExpand} >
            <div className={`text`}>
                {isThought && props.labelThoughts && <div className={`thinking-label`}>({props.data.who ? `${props.data.who} T`:'T'}hinks...)</div>}

                {!isThought && props.labelSays && <div className={`says-label`}>({props.data.who ? `${props.data.who} S`:'S'}ays...)</div>}
                
                <span dangerouslySetInnerHTML={{__html:isThought ? props.data.thinks : props.data.says}}></span>
                </div>
            {(props.active )  && (props.data.flippable !== false) &&
                <button className="flip-btn">
                    <img src={'assets/flip_icon.png'} alt="flip icon"/>
                </button>
            }

            {!props.disableTail && <div className={`tail ${props.data.location.tail}`}>
                <img className={`thought-bub ${isThought ? 'not-hidden' : 'hidden'}`} src={bubTail} alt="thought bubble tail"/>
                <img className={`talk-bub ${!isThought ? 'not-hidden' : 'hidden'}`} src={talkTail} alt="talk bubble tail"/>

            </div>}
        </div>
    )
}

export default Bubble