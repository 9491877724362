import React, { useState } from "react";

const Part1 = (props) => {
  const { data, onComplete } = props;

  const [answer, setAnswer] = useState(-1);

  return (
    <div className="Part1">
      <div className={`question-text`}>
        <h1> {data.question}</h1>
      </div>
      <div className={`options`}>
        {data.options.map((option, optionIndex) => {
            if(answer > -1 && answer !== optionIndex) return null;
          return (
            <button
              className={`option-btn ${
                  answer === -1 ? 'default' : 
                answer === optionIndex ? "selected" : "not-selected"
              }`}
              key={`option${optionIndex}`}
              onClick={() => setAnswer(optionIndex)}
            >
              {option.text}
            </button>
          );
        })}
       
      </div>
      {answer > -1 && (
          <div className={`feedback`}>
            <div
              className={`text`}
              dangerouslySetInnerHTML={{
                __html: data.options[answer].feedback,
              }}
            ></div>
            <button
              className="next-btn std-button"
              onClick={onComplete}
              disabled={answer === -1}
            >
              {"Explore"}
            </button>
          </div>
        )}
    </div>
  );
};

export default Part1;
