import "./sass/reset.scss";
import "./sass/main.scss";
import Course from './engine/Course.js'

const App = () => {
  return <div className="App">
    
    {/* main course frame */}
    <Course/>

  </div>;
};

export default App;
