/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'

const Jira = props => {

    const removeAllJiraElements = () => {
        //get rid of all current jira tags so new ones can be created with current data
        const ids = ['jiraTag', 'atlwdg-trigger', 'atlwdg-blanket' ,'atlwdg-container'];
        ids.forEach(id => {
            const elem = document.getElementById(id);
            if(elem != null){
                elem.parentNode.removeChild(elem);
            }        
        })
    }

    const setNewJiraFields = () => {
        const moduleIdentifier = `module-${props.location.module + 1}`
        const slideIdentifier = `slide-${props.location.slide + 1}`

        const interactionIdentifier = `${props.interactionType}`
      
        //this is setting up fields for jira
        window.ATL_JQ_PAGE_PROPS = {
            environment : function(){
                var env_info = {};    
                return env_info;
            }, 
            fieldValues:{
                'customfield_10028': `${moduleIdentifier} ${slideIdentifier}`,
                'customfield_10030': `${interactionIdentifier}`,
            }                  
        }
    }

    const createJiraTag = () => {
        //create new jira tag based on props.jiraTag
        const scriptTag = document.createElement('script');
        scriptTag.id = 'jiraTag';
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.src = props.jiraTag;
        document.body.appendChild(scriptTag);
    }

    useEffect(()=>{
        //every time current location or jira tag changes, refresh jira
        if(props.jiraTag && props.location){
            removeAllJiraElements();
            setNewJiraFields();
            createJiraTag();
        }
    }, [props.jiraTag, props.location])


    return (
        //we don't need to render anything, handled by jira script tag from atlassian
        <></>
    )
}

export default Jira