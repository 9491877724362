import React, { useState } from "react";

const Part3 = (props) => {
  const { data, onComplete } = props;

  const [currentText, setCurrentText] = useState("");
  const [inThankyou, setInThankyou] = useState(false);

  const handleChange = (e) => {
    if (inThankyou) return;
    setCurrentText(e.currentTarget.value);
  };
  const handleSubmit = () => {
    setInThankyou(true);
  };

  return (
    <div className="Part3">
      <div className={`main-fb`}>
        <div className={`heading`}>
          <h1>{data.heading}</h1>
        </div>
        <div className={`wrap`}>
          <div
            className={`fb-body`}
            dangerouslySetInnerHTML={{ __html: data.body }}
          ></div>
          <div className={`reflection`}>
            {!inThankyou && (
              <div className={`group`}>
                <div className={`question`}>{data.reflection}</div>
                <textarea
                  value={currentText}
                  onChange={handleChange}
                ></textarea>
                <button
                  disabled={currentText === ""}
                  className="std-button"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            )}{" "}
            {inThankyou && (
              <div className={`thanks`}>
                <p>Thank you for taking the time to respond.</p>
                <button className="std-button" onClick={onComplete}>Next</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part3;
