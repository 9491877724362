import React, { useEffect, useState } from "react";
import './FloatingSquares.scss';

const FloatingSquares = props => {
    const {data, onComplete} = props;

    const {squares} = data;

    const [animationState, setAnimationState] = useState('initial');
    

    useEffect(()=>{
        setTimeout(()=>{
            setAnimationState('settled')
        }, 200)
    }, [])


    const handleNext = () => {
        setAnimationState('final');
        setTimeout(onComplete, 1000);
    }

    const genNum = () => {
        const mag = 190;
        const flat = 5;
        const offset = Math.round(Math.random()*mag) - (mag/2);
        return offset > 0 ? offset + flat : offset - flat;
    }

    return <div className="FloatingSquares">
        <div className={`heading ${animationState}`}>
            <h1>{data.heading}</h1>
        </div>
        {
            squares.map((el, i) => {
                return <div className={`square ${animationState}`} key={`sqr${i}`}
                style={{
                    zIndex:i + 1,
                    transform:`translate(${genNum()}px,${genNum()}px)`,
                    transitionDelay:`${i*1.5}s`,
                    ...el.position
                }}>
                    <img src={el.image.file} alt={el.image.alt}/>
                    <div className={`quotes`}>
                        {
                            el.quotes.map((quote, quoteIndex) => {
                                return <div className={`quote`} key={'q' + quoteIndex} style={{
                                    ...quote.position}
                                    }>
                                    {
                                        quote.text
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            })
        }
        <button className={'std-button'} onClick={handleNext}>Next</button>
    </div>

}

export default FloatingSquares;