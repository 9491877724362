import Pager from './interactions/Pager/Pager.js'
import SelfReflection from './interactions/SelfReflection/SelfReflection.js';
import AudienceSelection from './interactions/AudienceSelection/AudienceSelection.js'
import SingleSelect from './interactions/SingleSelect/SingleSelect.js'
import ETSModel from './interactions/ETSModel/ETSModel.js';
import InterventionModel from './interactions/InterventionModel/InterventionModel.js';
import FloatingSquares from './interactions/FloatingSquares/FloatingSquares.js';

const InteractionLoader = (interactionType, props) => {
    if (typeof interactionType !== "string") return null;

    switch (interactionType.toLowerCase().trim()) {
        case "pager":
            return <Pager {...props} />
        case "self-reflection":
            return <SelfReflection {...props} />
        case "audience-selection":
            return <AudienceSelection {...props} />
        case "single-select":
            return <SingleSelect {...props} />
        case "ets-model":
            return <ETSModel {...props} />
        case "intervention-model":
            return <InterventionModel {...props} />
        case "floating-squares":
            return <FloatingSquares {...props} />
        default:
            return <Pager {...props} data={{
                "type": "pager",
                "heading": "Under Construction",
                "layout": "full-page",
                "body": ` <p>The interaction type ${interactionType} is still in development and not yet ready for review. Click 'Next' to move on.</p> `
            }} />
    }
}

export default InteractionLoader